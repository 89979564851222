import Swiper from 'swiper';
import { EffectCoverflow, Navigation } from 'swiper/modules';

const slider = '#js-banner-slider';
let $title = jQuery('#js-slide-title'),
    $text = jQuery('#js-slide-text'),
    $link = jQuery('#js-slide-link');

let $dataLink = $link.attr('data-start');

// Swiper init
const bannerSlider = new Swiper(slider, {
    modules: [EffectCoverflow, Navigation],
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    initialSlide: 1,
    coverflowEffect: {
        rotate: 0,
        stretch: 30,
        depth: 250,
        modifier: 3,
        slideShadows: false,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

// Zmiana koloru
bannerSlider.on('slideChange', function () {
    let activeIndex = bannerSlider.activeIndex,
        activeClass = false;
    switch (activeIndex) {
        case 0:
            jQuery('.banner-slider').removeClass('-green -blue'); // TODO: poprawić zmianę kolorów
            _bannerSliderChangeContent(
                'Krem ochronny <strong>dla niemowląt i dzieci</strong> Cudocell',
                'Pieluszkowe <strong>odparzenia skóry</strong>',
                $dataLink + '/produkty/cudocell-max-baby/'
            );
            activeClass = '-red';
            break;
        case 1:
            jQuery('.banner-slider').removeClass('-blue -red'); // TODO: poprawić zmianę kolorów
            _bannerSliderChangeContent(
                'Emulsja <strong>do twarzy i ciała</strong> Cudocell',
                'Pielęgnacja <strong>skóry</strong>',
                $dataLink + '/produkty/cudocell-baby-derm-ulga-juz-w-5-minut/'
            );
            activeClass = '-green';
            break;
        case 2:
            jQuery('.banner-slider').removeClass('-red -green'); // TODO: poprawić zmianę kolorów
            _bannerSliderChangeContent(
                'Żel do mycia, szampon i płyn do kąpieli <strong>Cudocell</strong>',
                'Mycie <strong>i kąpiel</strong>',
                $dataLink +
                    '/produkty/cudocell-baby-derm-3w1-nie-szczypie-w-oczy/'
            );
            activeClass = '-blue';
            break;
    }

    jQuery('.banner-slider').addClass(activeClass);
});

// Zmiana treści na slajdach
function _bannerSliderChangeContent(title, text, link) {
    if (title) $title.hide().html(title).fadeIn();
    if (text) $text.hide().html(text).fadeIn();
    if (link) $link.attr('href', link);
}
